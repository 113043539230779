import React,{Fragment,useState,useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import {currentRouteSet} from '../actions/actions';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {pathSpliter,checkAuthBranchWare,dateTimeFormat,checkIntNum,currentDateTime,getDateTimeFromISODT} from '../lib/functions'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {APP_URL,API_URL} from '../config.json';
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import CheckboxIndependant from "./independant";


import axios from 'axios';
import SaveIcon from '@material-ui/icons/Save';


import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import swal from 'sweetalert';
import { CardContent } from '@material-ui/core';

import socketIOClient from "socket.io-client";


const UserAccess = ({location,currentRoute,currentRouteSet,authInfo})=>{

    const classes = useStyles();
    const [selectedDate, handleDateChangeSet] = useState(currentDateTime);
    const [userId, userIdSet] = useState(0);
    const [access, accessSet] = useState([]);
    const [user_full_name, user_full_name_set] = useState('Loading...');


    


  let moduleAllCheck = (event)=>{
      let elem = document
        .getElementById(`${event.target.name}`)
        .querySelectorAll("input[type='checkbox']");
      for (let i = 0; i < elem.length; i++) {

        checkExistAccess(elem[i])
        elem[i].checked = !event.target.checked;
        elem[i].click()
      }
     
      reset(event)

  
  }


  const logout = ()=>{
    sessionStorage.clear();
        window.location.href = `${APP_URL}`
  }
  


  useEffect(()=>{
    const socket = socketIOClient(API_URL); 

    
    socket.on("accessChanged", (data) => {
          console.log(authInfo.userInfo.user_id,' -- ',data.user_id)
         if(data.access=='changed' && (authInfo.userInfo.user_id == data.user_id)){
          swal({
            title:data.msg,
            icon:'warning',

          }).then(res=>{
            if(res){
              logout()
            }else{
              logout()
            }
          })
           
          
         }
    });


  },[])

  let checkExistAccess = (e)=>{
    let accessTemp = access
    let tg = e.name
    let check =  access.findIndex((ele)=>{
      if(ele==tg) return true;
    });

  if(e.checked && check == -1){
    accessTemp.push(tg)
  }

  if(!e.checked && check > -1){
    accessTemp.splice(check,1)
  }
   accessSet(accessTemp)
  }



  const handleChange = (e,state,stateFun) => {
      checkExistAccess(e.target)
      if(!state){
        stateFun(true)
      }else{
        stateFun(false)
      }
  };


    useEffect(()=>{
        currentRouteSet(pathSpliter(location.pathname,1));
        userIdSet(parseFloat(pathSpliter(location.pathname,3)))
         axios.post(`${API_URL}/api/get-access`,{userId:parseFloat(pathSpliter(location.pathname,3))},{headers:{'auth-token':authInfo.token}}).then(res=>{
          user_full_name_set(res.data.user_full_name)
          
          let accessArr =  JSON.parse(res.data.user_access)
        accessSet(accessArr) 
       checkAccess(accessArr)
     })
     
    },[]);


    


    const userAccessBack = ()=>{
        history.push(`/administration/users-manage`)
     }

    

    
     
     let userAccessSave = async () =>{
         await axios.post(`${API_URL}/api/save-access`,{access,userId},{headers:{'auth-token':authInfo.token}}).then(res=>{
            if(authInfo.userInfo.user_id != res.data.user_id){
              swal({
                title:`${res.data.message}`,
                icon:'success'
              })
            }
          
         })
     }


     let reset = (event)=>{
      let acc  = access

      if(event.target.name=='order'){
        if(event.target.checked){
          sales_order_entry_set(true)
          purchase_order_entry_set(true)
          purchase_order_voucher_set(true)
          sales_order_voucher_set(true)
          sales_order_record_set(true)
          purchase_order_record_set(true)
          order_set(true)

  
        
          }else{
            sales_order_entry_set(false)
            purchase_order_entry_set(false)
            purchase_order_voucher_set(false)
            sales_order_voucher_set(false)
            sales_order_record_set(false)
            purchase_order_record_set(false)
            order_set(false)

            
          }
      }


      if(event.target.name=='sales'){
        if(event.target.checked){
          sales_set(true)
          sales_entry_set(true)
          sales_record_set(true)
  
          sales_voucher_set(true)
       
          sales_return_set(true)
          sales_return_voucher_set(true)
          sales_return_record_set(true)
        
  
        
          }else{
            sales_set(false)
            sales_entry_set(false)
            sales_record_set(false)
  
            sales_voucher_set(false)
            sales_return_voucher_set(false)
         
            sales_return_set(false)
            sales_return_record_set(false)
          
            
          }
      }

      // Service

      if(event.target.name=='service'){

        if(event.target.checked){
          service_set(true)
          service_entry_set(true)
          service_expense_entry_set(true)
          service_record_set(true)
          service_expense_record_set(true)
          service_voucher_set(true)
          service_expense_voucher_set(true)
        }else{
          service_set(false)
          service_entry_set(false)
          service_expense_entry_set(false)
          service_record_set(false)
          service_expense_record_set(false)
          service_voucher_set(false)
          service_expense_voucher_set(false)
        }

      }
      
       // Purchase
       if(event.target.name=='purchase'){
        if(event.target.checked){
          purchase_set(true)
          purchase_entry_set(true)
          purchase_record_set(true)
          purchase_voucher_set(true)
          purchase_return_voucher_set(true)
        
          purchase_return_set(true)
          purchase_return_record_set(true)
         
          }else{
            purchase_set(false)
            purchase_entry_set(false)
            purchase_record_set(false)
            purchase_voucher_set(false)
            purchase_return_voucher_set(false)
         
            purchase_return_set(false)
            purchase_return_record_set(false)
        
          }
       }

         // Production
         if(event.target.name=='production'){
          if(event.target.checked){
            production_set(true)
            production_entry_set(true)
          
            production_voucher_set(true)
          
            production_record_set(true)
         
          }else{
            production_set(false)
            production_entry_set(false)
            production_voucher_set(false)
            production_record_set(false)
      
         
          }
        }

        // Stock

        if(event.target.name=='inventory'){
          if(event.target.checked){
            inventory_set(true)
            item_stock_report_set(true)
            item_ledger_set(true)
            transfer_entry_set(true)
            item_adjustment_entry_set(true)
            adjustment_record_set(true)
            transfer_record_set(true)
            transfer_pending_record_set(true)
            transfer_receive_record_set(true)
            adjustment_record_set(true)
           
          }else{
            inventory_set(false)
            item_stock_report_set(false)
            item_ledger_set(false)
            transfer_entry_set(false)
            item_adjustment_entry_set(false)
            adjustment_record_set(false)
            transfer_record_set(false)
            transfer_pending_record_set(false)
            transfer_receive_record_set(false)
            adjustment_record_set(false)
          }

        }

        if(event.target.name=='accounts'){
          if(event.target.checked){
            accounts_set(true)
            creditor_payment_set(true)
            debtor_receipt_set(true)
            expense_entry_set(true)
            income_entry_set(true)
            contra_entry_set(true)
            journal_entry_set(true)
            account_entry_set(true)
            location_entry_set(true)
            branch_tran_set(true)
            advance_transaction_entry_set(true)

            
          }else{
            accounts_set(false)
            creditor_payment_set(false)
            debtor_receipt_set(false)
            expense_entry_set(false)
            income_entry_set(false)
            contra_entry_set(false)
            journal_entry_set(false)
            account_entry_set(false)
            location_entry_set(false)
            branch_tran_set(false)
            advance_transaction_entry_set(false)


          }

        }

        if(event.target.name=='hrpayroll'){
          if(event.target.checked){
            hrpayroll_set(true)
            salary_payment_set(true)
            employee_entry_set(true)
            department_entry_set(true)
            designation_entry_set(true)

            attendance_entry_set(true)
            monthly_salary_report_set(true)

           

           
          }else{
            hrpayroll_set(false)
            salary_payment_set(false)
            employee_entry_set(false)
            department_entry_set(false)
            designation_entry_set(false)
            attendance_entry_set(false)
            monthly_salary_report_set(false)
          }
        }


        // settings
        if(event.target.name=='administration'){
          if(event.target.checked){
            administration_set(true)
            item_entry_set(true)
            item_unit_set(true)
            user_entry_set(true)
            branch_entry_set(true)
            company_profile_set(true)
            group_entry_set(true)
            warehouse_entry_set(true)
            sms_sender_set(true)
          }else{
            administration_set(false)
            item_entry_set(false)
            item_unit_set(false)
            user_entry_set(false)
            branch_entry_set(false)
            company_profile_set(false)
            group_entry_set(false)
            warehouse_entry_set(false)
            sms_sender_set(false)
          }
        }

        // Reports
        if(event.target.name=='reports'){
          if(event.target.checked){
              reports_set(true)
              balance_sheet_set(true)
              trial_balance_set(true)
              profit_loss_set(true)
              cash_bank_balance_set(true)
              loan_balance_set(true)
              indirect_expense_balance_set(true)
              indirect_income_balance_set(true)
              fixed_asset_balance_set(true)
              capital_balance_set(true)
              debtor_balance_set(true)
              branch_balance_set(true)
              creditor_balance_set(true)
              fixed_asset_ledger_set(true)
              loan_ledger_set(true)
              indirect_expense_ledger_set(true)
              indirect_income_ledger_set(true)
              cash_bank_ledger_set(true)
              capital_ledger_set(true)
              debtor_ledger_set(true)
              creditor_ledger_set(true)
              sales_ledger_set(true)
              purchase_ledger_set(true)
              service_expense_ledger_set(true)
              service_ledger_set(true)
              sales_return_ledger_set(true)
              purchase_return_ledger_set(true)
              tax_ledger_set(true)
              direct_expense_ledger_set(true)
              direct_expense_balance_set(true)
              direct_income_balance_set(true)
              direct_income_ledger_set(true)

            debtor_receipt_record_set(true)
            creditor_payment_record_set(true)
            expense_record_set(true)
            income_record_set(true)
            journal_record_set(true)
            contra_record_set(true)
            salary_report_set(true)
            item_wise_profit_loss_set(true)

            branch_tran_pen_list_set(true)
            branch_tran_rcv_list_set(true)
            branch_tran_transfer_list_set(true)
            branch_ledger_set(true)
            daily_ledger_set(true)

            advance_tran_record_set(true)
            advance_debtor_balance_set(true)
            advance_creditor_balance_set(true)

            
          }else{
            reports_set(false)
            reports_set(false)
            balance_sheet_set(false)
            trial_balance_set(false)
            profit_loss_set(false)
            cash_bank_balance_set(false)
            loan_balance_set(false)
            indirect_expense_balance_set(false)
            indirect_income_balance_set(false)
            fixed_asset_balance_set(false)
            capital_balance_set(false)
            debtor_balance_set(false)
            branch_balance_set(false)
            creditor_balance_set(false)
            fixed_asset_ledger_set(false)
            loan_ledger_set(false)
            indirect_expense_ledger_set(false)
            indirect_income_ledger_set(false)
            cash_bank_ledger_set(false)
            capital_ledger_set(false)
            debtor_ledger_set(false)
            creditor_ledger_set(false)
            sales_ledger_set(false)
            purchase_ledger_set(false)
            service_expense_ledger_set(false)
            service_ledger_set(false)
            sales_return_ledger_set(false)
            purchase_return_ledger_set(false)
            tax_ledger_set(false)
            direct_expense_ledger_set(false)
            direct_expense_balance_set(false)
            direct_income_balance_set(false)
            direct_income_ledger_set(false)


            debtor_receipt_record_set(false)
            creditor_payment_record_set(false)
            expense_record_set(false)
            income_record_set(false)
            journal_record_set(false)
            contra_record_set(false)
            salary_report_set(false)
            item_wise_profit_loss_set(false)

            branch_tran_pen_list_set(false)
            branch_tran_rcv_list_set(false)
            branch_tran_transfer_list_set(false)
            branch_ledger_set(false)
            daily_ledger_set(false)



            advance_tran_record_set(false)
            advance_debtor_balance_set(false)
            advance_creditor_balance_set(false)
            
          }
        }



      if(event.target.name=='quotation'){
        if(event.target.checked){
          quotation_set(true)
          quotation_entry_set(true)
          quotation_record_set(true)
          
        
          }else{
            quotation_set(false)
            quotation_entry_set(false)
            quotation_record_set(false)
            
           
            
          }
      }
       
    }

    
     let checkAccess = (acc)=>{
      acc.forEach(element => {
            //   Sales 
              if(element=='sales_entry'){
                sales_entry_set(true)
              }

              if(element=='sales_record'){
                sales_record_set(true)
              }

              if(element=='sales_voucher'){
                sales_voucher_set(true)
              }


  
            
              if(element=='sales_return'){
                sales_return_set(true)
              }

              if(element=='sales_return_voucher'){
                sales_return_voucher_set(true)
              }
              if(element=='sales_return_record'){
                sales_return_record_set(true)
              }
           

              // Service

              if(element=='service_entry'){
                service_entry_set(true)
              }
              if(element=='service_expense_entry'){
                service_expense_entry_set(true)
              }

              if(element=='service_record'){
                service_record_set(true)
              }

              if(element=='service_expense_record'){
                service_expense_record_set(true)
              }
              if(element=='service_voucher'){
                service_voucher_set(true)
              }
              if(element=='service_expense_voucher'){
                service_expense_voucher_set(true)
              }
             


              // Purchase
              if(element=='purchase_entry'){
                purchase_entry_set(true)
              }
              if(element=='purchase_record'){
                purchase_record_set(true)
              }
              if(element=='purchase_voucher'){
                purchase_voucher_set(true)
              }

              if(element=='purchase_return_voucher'){
                purchase_return_voucher_set(true)
              }
             
              if(element=='purchase_return'){
                purchase_return_set(true)
              }
              if(element=='purchase_return_record'){
                purchase_return_record_set(true)
              }
            
              // production
              if(element=='production_entry'){
                production_entry_set(true)
              }
           
              
              if(element=='production_voucher'){
                production_voucher_set(true)
              }
             
              if(element=='production_record'){
                production_record_set(true)
              }

            

              // Stock 
           
           


              // Accounts
            
              // HR Payroll
              
             

              // settings
              if(element=='item_entry'){
                item_entry_set(true)
              }
              if(element=='location_entry'){
                location_entry_set(true)
              }
             
              if(element=='item_category'){
                item_category_set(true)
              }
              if(element=='item_unit'){
                item_unit_set(true)
              }
              if(element=='user_entry'){
                user_entry_set(true)
              }
              if(element=='branch_entry'){
                branch_entry_set(true)
              }
              if(element=='company_profile'){
                company_profile_set(true)
              }

            

             

               if(element=='sales_order_entry'){
                sales_order_entry_set(true)
              }

              if(element=='purchase_order_entry'){
                purchase_order_entry_set(true)
              }

              if(element=='purchase_order_voucher'){
                purchase_order_voucher_set(true)
              }

              if(element=='sales_order_voucher'){
                sales_order_voucher_set(true)
              }

              if(element=='sales_order_record'){
                sales_order_record_set(true)
              }

              if(element=='purchase_order_record'){
                purchase_order_record_set(true)
              }
              if(element=='order'){
                order_set(true)
              }

              if(element=='inventory'){
                inventory_set(true)
              }

              if(element=='item_stock_report'){
                item_stock_report_set(true)
              }

              if(element=='item_ledger'){
                item_ledger_set(true)
              }

              if(element=='transfer_entry'){
                transfer_entry_set(true)
              }

              if(element=='item_adjustment_entry'){
                item_adjustment_entry_set(true)
              }

              if(element=='adjustment_record'){
                adjustment_record_set(true)
              }

              if(element=='transfer_record'){
                transfer_record_set(true)
              }

              if(element=='transfer_pending_record'){
                transfer_pending_record_set(true)
              }
              if(element=='transfer_receive_record'){
                transfer_receive_record_set(true)
              }


              if(element=='creditor_payment'){
                creditor_payment_set(true)
              }

              if(element=='debtor_receipt'){
                debtor_receipt_set(true)
              }

              if(element=='expense_entry'){
                expense_entry_set(true)
              }

              if(element=='income_entry'){
                income_entry_set(true)
              }

              if(element=='contra_entry'){
                contra_entry_set(true)
              }

              if(element=='journal_entry'){
                journal_entry_set(true)
              }

              if(element=='account_entry'){
                account_entry_set(true)
              }

           

              if(element=='hrpayroll'){
                hrpayroll_set(true)
              }

              if(element=='salary_payment'){
                salary_payment_set(true)
              }
              if(element=='employee_entry'){
                employee_entry_set(true)
              }

              if(element=='department_entry'){
                department_entry_set(true)
              }

              if(element=='designation_entry'){
                designation_entry_set(true)
              }


              if(element=='group_entry'){
                group_entry_set(true)
              }

              if(element=='warehouse_entry'){
                warehouse_entry_set(true)
              }


              

              if(element=='advance_transaction_entry'){
                advance_transaction_entry_set(true)
              }

              //  reports

              if(element=='balance_sheet'){
                balance_sheet_set(true)
              }
              if(element=='trial_balance'){
                trial_balance_set(true)
              }
              if(element=='profit_loss'){
                profit_loss_set(true)
              }
              if(element=='cash_bank_balance'){
                cash_bank_balance_set(true)
              }

              if(element=='loan_balance'){
                loan_balance_set(true)
              }

              if(element=='indirect_expense_balance'){
                indirect_expense_balance_set(true)
              }


              if(element=='indirect_income_balance'){
                indirect_income_balance_set(true)
              }

              if(element=='fixed_asset_balance'){
                fixed_asset_balance_set(true)
              }

              if(element=='capital_balance'){
                capital_balance_set(true)
              }

              if(element=='debtor_balance'){
                debtor_balance_set(true)
              }

              if(element=='branch_balance'){
                branch_balance_set(true)
              }


              if(element=='creditor_balance'){
                creditor_balance_set(true)
              }

              if(element=='fixed_asset_ledger'){
                fixed_asset_ledger_set(true)
              }

              if(element=='loan_ledger'){
                loan_ledger_set(true)
              }

              if(element=='indirect_expense_ledger'){
                indirect_expense_ledger_set(true)
              }

              if(element=='indirect_income_ledger'){
                indirect_income_ledger_set(true)
              }

              if(element=='cash_bank_ledger'){
                cash_bank_ledger_set(true)
              }

              if(element=='capital_ledger'){
                capital_ledger_set(true)
              }

              if(element=='debtor_ledger'){
                debtor_ledger_set(true)
              }

              if(element=='creditor_ledger'){
                creditor_ledger_set(true)
              }
              if(element=='sales_ledger'){
                sales_ledger_set(true)
              }

              if(element=='purchase_ledger'){
                purchase_ledger_set(true)
              }

              if(element=='service_expense_ledger'){
                service_expense_ledger_set(true)
              }

              if(element=='service_ledger'){
                service_ledger_set(true)
              }
              if(element=='sales_return_ledger'){
                sales_return_ledger_set(true)
              }

              if(element=='purchase_return_ledger'){
                purchase_return_ledger_set(true)
              }

              if(element=='tax_ledger'){
                tax_ledger_set(true)
              }

              if(element=='direct_expense_ledger'){
                direct_expense_ledger_set(true)
              }

              if(element=='direct_expense_balance'){
                direct_expense_balance_set(true)
              }

              if(element=='direct_income_balance'){
                direct_income_balance_set(true)
              }

              if(element=='direct_income_ledger'){
                direct_income_ledger_set(true)
              }

              if(element=='debtor_receipt_record'){
                debtor_receipt_record_set(true)
              }

              if(element=='creditor_payment_record'){
                creditor_payment_record_set(true)
              }
              if(element=='expense_record'){
                expense_record_set(true)
              }

              if(element=='income_record'){
                income_record_set(true)
              }

              if(element=='journal_record'){
                journal_record_set(true)
              }

              if(element=='contra_record'){
                contra_record_set(true)
              }

              if(element=='salary_report'){
                salary_report_set(true)
              }

              if(element=='attendance_entry'){
                attendance_entry_set(true)
              }

              if(element=='monthly_salary_report'){
                monthly_salary_report_set(true)
              }

              if(element=='item_wise_profit_loss'){
                item_wise_profit_loss_set(true)
              }


              if(element=='branch_tran_pen_list'){
                branch_tran_pen_list_set(true)
              }

              if(element=='branch_tran_rcv_list'){
                branch_tran_rcv_list_set(true)
              }

              if(element=='branch_tran_transfer_list'){
                branch_tran_transfer_list_set(true)
              }

              if(element=='branch_ledger'){
                branch_ledger_set(true)
              }

              if(element=='daily_ledger'){
                daily_ledger_set(true)
              }


              if(element=='advance_tran_record'){
                advance_tran_record_set(true)
              }

              if(element=='advance_debtor_balance'){
                advance_debtor_balance_set(true)
              }

              if(element=='advance_creditor_balance'){
                advance_creditor_balance_set(true)
              }




              if(element=='quotation_entry'){
                quotation_entry_set(true)
               }

               if(element=='quotation_record'){
                quotation_record_set(true)
               }


           


           
              


              

              
      });
     }
     

    // Sales
    const [sales, sales_set] = useState(true);
    const [sales_entry, sales_entry_set] = useState(false);
    const [sales_record, sales_record_set] = useState(false);
    const [sales_voucher, sales_voucher_set] = useState(false);

    const [sales_return, sales_return_set] = useState(false);
    const [sales_return_voucher, sales_return_voucher_set] = useState(false);
    const [sales_return_record, sales_return_record_set] = useState(false);


    // Service
    const [service, service_set] = useState(false);
    const [service_entry, service_entry_set] = useState(false);
    const [service_expense_entry, service_expense_entry_set] = useState(false);
    const [service_record, service_record_set] = useState(false);
    const [service_expense_record, service_expense_record_set] = useState(false);
    const [service_voucher, service_voucher_set] = useState(false);
    const [service_expense_voucher, service_expense_voucher_set] = useState(false);


 

  // Purchase
  const [purchase, purchase_set] = useState(true);
  const [purchase_entry, purchase_entry_set] = useState(false);
  const [purchase_record, purchase_record_set] = useState(false);
  const [purchase_voucher, purchase_voucher_set] = useState(false);
  const [purchase_return, purchase_return_set] = useState(false);
  const [purchase_return_voucher, purchase_return_voucher_set] = useState(false);
  const [purchase_return_record, purchase_return_record_set] = useState(false);


//  Production
const [production, production_set] = useState(true);
const [production_entry, production_entry_set] = useState(false);

const [production_voucher, production_voucher_set] = useState(false);

const [production_record, production_record_set] = useState(false);



// Stock 
const [inventory, inventory_set] = useState(false);


// Accounts
const [accounts, accounts_set] = useState(false);

const [advance_transaction_entry, advance_transaction_entry_set] = useState(false);


// HR Payroll


// settings
const [administration, administration_set] = useState(false);
const [item_entry, item_entry_set] = useState(false);
const [item_category,item_category_set] = useState(false);
const [item_unit, item_unit_set] = useState(false);
const [user_entry, user_entry_set] = useState(false);
const [branch_entry, branch_entry_set] = useState(false);
const [company_profile, company_profile_set] = useState(false);
const [group_entry, group_entry_set] = useState(false);
const [warehouse_entry, warehouse_entry_set] = useState(false);



// Reports
const [reports, reports_set] = useState(false);


const [order, order_set] = useState(false);
const [sales_order_entry, sales_order_entry_set] = useState(false);
const [purchase_order_entry, purchase_order_entry_set] = useState(false);
const [purchase_order_voucher, purchase_order_voucher_set] = useState(false);
const [sales_order_voucher, sales_order_voucher_set] = useState(false);
const [sales_order_record, sales_order_record_set] = useState(false);
const [purchase_order_record, purchase_order_record_set] = useState(false);


const [item_stock_report, item_stock_report_set] = useState(false);
const [item_ledger, item_ledger_set] = useState(false);
const [transfer_entry, transfer_entry_set] = useState(false);
const [item_adjustment_entry, item_adjustment_entry_set] = useState(false);
const [adjustment_record, adjustment_record_set] = useState(false);
const [transfer_record, transfer_record_set] = useState(false);
const [transfer_pending_record, transfer_pending_record_set] = useState(false);
const [transfer_receive_record, transfer_receive_record_set] = useState(false);


const [creditor_payment, creditor_payment_set] = useState(false);
const [debtor_receipt, debtor_receipt_set] = useState(false);
const [expense_entry,expense_entry_set] = useState(false);
const [contra_entry, contra_entry_set] = useState(false);
const [income_entry, income_entry_set] = useState(false);
const [journal_entry, journal_entry_set] = useState(false);
const [account_entry, account_entry_set] = useState(false);
const [location_entry,location_entry_set] = useState(false);

const [hrpayroll, hrpayroll_set] = useState(false);

const [salary_payment,salary_payment_set] = useState(false);
const [employee_entry,employee_entry_set] = useState(false);
const [department_entry,department_entry_set] = useState(false);
const [designation_entry,designation_entry_set] = useState(false);


const [balance_sheet,balance_sheet_set] = useState(false);
const [trial_balance,trial_balance_set] = useState(false);
const [profit_loss,profit_loss_set] = useState(false);
const [cash_bank_balance,cash_bank_balance_set] = useState(false);
const [loan_balance,loan_balance_set] = useState(false);
const [indirect_expense_balance,indirect_expense_balance_set] = useState(false);
const [indirect_income_balance,indirect_income_balance_set] = useState(false);
const [fixed_asset_balance,fixed_asset_balance_set] = useState(false);
const [capital_balance,capital_balance_set] = useState(false);
const [debtor_balance,debtor_balance_set] = useState(false);
const [branch_balance,branch_balance_set] = useState(false);
const [creditor_balance,creditor_balance_set] = useState(false);
const [fixed_asset_ledger,fixed_asset_ledger_set] = useState(false);
const [loan_ledger,loan_ledger_set] = useState(false);
const [indirect_expense_ledger,indirect_expense_ledger_set] = useState(false);
const [indirect_income_ledger,indirect_income_ledger_set] = useState(false);
const [cash_bank_ledger,cash_bank_ledger_set] = useState(false);
const [capital_ledger,capital_ledger_set] = useState(false);
const [debtor_ledger,debtor_ledger_set] = useState(false);
const [creditor_ledger,creditor_ledger_set] = useState(false);
const [sales_ledger,sales_ledger_set] = useState(false);
const [purchase_ledger,purchase_ledger_set] = useState(false);
const [service_expense_ledger,service_expense_ledger_set] = useState(false);
const [service_ledger,service_ledger_set] = useState(false);
const [sales_return_ledger,sales_return_ledger_set] = useState(false);
const [purchase_return_ledger,purchase_return_ledger_set] = useState(false);
const [tax_ledger,tax_ledger_set] = useState(false);
const [direct_expense_ledger,direct_expense_ledger_set] = useState(false);
const [direct_expense_balance,direct_expense_balance_set] = useState(false);
const [direct_income_balance,direct_income_balance_set] = useState(false);
const [direct_income_ledger,direct_income_ledger_set] = useState(false);

const [debtor_receipt_record,debtor_receipt_record_set] = useState(false);
const [creditor_payment_record,creditor_payment_record_set] = useState(false);
const [expense_record,expense_record_set] = useState(false);
const [income_record,income_record_set] = useState(false);
const [journal_record,journal_record_set] = useState(false);
const [contra_record,contra_record_set] = useState(false);
const [salary_report,salary_report_set] = useState(false);

const [attendance_entry,attendance_entry_set] = useState(false);
const [monthly_salary_report,monthly_salary_report_set] = useState(false);
const [item_wise_profit_loss,item_wise_profit_loss_set] = useState(false);


const [branch_tran,branch_tran_set] = useState(false);
const [branch_tran_pen_list,branch_tran_pen_list_set] = useState(false);
const [branch_tran_rcv_list,branch_tran_rcv_list_set] = useState(false);
const [branch_tran_transfer_list,branch_tran_transfer_list_set] = useState(false);
const [branch_ledger,branch_ledger_set] = useState(false);
const [daily_ledger,daily_ledger_set] = useState(false);
const [sms_sender,sms_sender_set] = useState(false);

const [advance_tran_record,advance_tran_record_set] = useState(false);
const [advance_debtor_balance,advance_debtor_balance_set] = useState(false);
const [advance_creditor_balance,advance_creditor_balance_set] = useState(false);
 


// Quotation
const [quotation, quotation_set] = useState(false);
const [quotation_entry, quotation_entry_set] = useState(false);
const [quotation_record, quotation_record_set] = useState(false);


    const history = useHistory();
    
      return(
          <div className={classes.root}> 
<Paper className={classes.paper} style={{marginTop:'-25px',marginBottom:'5px'}}>
<h4 style={{textAlign:'left',margin:0,padding:0,marginTop: '-4px 0px 2px',
    marginBottom: '2px',color: '#3e8d54',
    fontSize: '18px'}}>User Access for {user_full_name}  </h4>

            <Grid container spacing={3}>
            <Grid item xs={12} sm={3} >
        
        <FormControl component="fieldset" className={classes.formControl}>

        <FormControlLabel style={{marginLeft: '-32px'}} 
            control={<Checkbox checked={sales}  onChange={(e)=>moduleAllCheck(e)} name="sales"  />}
            label="Sales"
          />

          
        <FormGroup>
        <div id="sales">
          <FormControlLabel
            control={<CheckboxIndependant checked={sales_entry} onChange={(e)=>handleChange(e,sales_entry,sales_entry_set)} name="sales_entry" />}
            label="Sales Entry" 
          />



          <FormControlLabel
            control={<CheckboxIndependant checked={sales_record} onChange={(e)=>handleChange(e,sales_record,sales_record_set)} name="sales_record" />}
            label="Sales Record"
          /> 
          <FormControlLabel
          control={<CheckboxIndependant checked={sales_voucher} onChange={(e)=>handleChange(e,sales_voucher,sales_voucher_set)} name="sales_voucher" />}
          label="Sales Voucher"
        />
       
       <FormControlLabel
          control={<CheckboxIndependant checked={sales_return_voucher} onChange={(e)=>handleChange(e,sales_return_voucher,sales_return_voucher_set)} name="sales_return_voucher" />}
          label="Sales Return Voucher"
        />
       
       
      
<FormControlLabel
          control={<CheckboxIndependant checked={sales_return} onChange={(e)=>handleChange(e,sales_return,sales_return_set)} name="sales_return" />}
          label="Sales Return Entry"
        />
        <FormControlLabel
          control={<CheckboxIndependant checked={sales_return_record} onChange={(e)=>handleChange(e,sales_return_record,sales_return_record_set)} name="sales_return_record" />}
          label="Sales Return Record"
        />


         </div>
        </FormGroup>
        <FormHelperText>Sales Module End</FormHelperText>
      </FormControl>
     
             </Grid>


             <Grid item xs={12} sm={3} >
        
        <FormControl component="fieldset" className={classes.formControl}>

        <FormControlLabel style={{marginLeft: '-32px'}} 
            control={<Checkbox checked={order}  onChange={(e)=>moduleAllCheck(e)} name="order"  />}
            label="Order"
          />

          
        <FormGroup>
        <div id="order">
        
         <FormControlLabel
            control={<CheckboxIndependant checked={sales_order_entry} onChange={(e)=>handleChange(e,sales_order_entry,sales_order_entry_set)} name="sales_order_entry" />}
            label="Sales Order Entry" 
          />

<FormControlLabel
            control={<CheckboxIndependant checked={purchase_order_entry} onChange={(e)=>handleChange(e,purchase_order_entry,purchase_order_entry_set)} name="purchase_order_entry" />}
            label="Purchase Order Entry" 
          />

<FormControlLabel
            control={<CheckboxIndependant checked={purchase_order_voucher} onChange={(e)=>handleChange(e,purchase_order_voucher,purchase_order_voucher_set)} name="purchase_order_voucher" />}
            label="Purchase Order Voucher" 
          />

<FormControlLabel
            control={<CheckboxIndependant checked={sales_order_voucher} onChange={(e)=>handleChange(e,sales_order_voucher,sales_order_voucher_set)} name="sales_order_voucher" />}
            label="Sales Order Voucher" 
          />
<FormControlLabel
            control={<CheckboxIndependant checked={sales_order_record} onChange={(e)=>handleChange(e,sales_order_record,sales_order_record_set)} name="sales_order_record" />}
            label="Sales Order Record" 
          />
<FormControlLabel
            control={<CheckboxIndependant checked={purchase_order_record} onChange={(e)=>handleChange(e,purchase_order_record,purchase_order_record_set)} name="purchase_order_record" />}
            label="Purchase Order Record" 
          />

         </div>
        </FormGroup>
        <FormHelperText>Order Module End</FormHelperText>
      </FormControl>
     
             </Grid>



             <Grid item xs={12} sm={3} >
        
        <FormControl component="fieldset" className={classes.formControl}>

        <FormControlLabel style={{marginLeft: '-32px'}} 
            control={<Checkbox checked={service}  onChange={(e)=>moduleAllCheck(e)} name="service"  />}
            label="Service"
          />

          
        <FormGroup>
        <div id="service">
          <FormControlLabel
            control={<CheckboxIndependant checked={service_entry} onChange={(e)=>handleChange(e,service_entry,service_entry_set)} name="service_entry" />}
            label="Service Entry" 
          />

<FormControlLabel
            control={<CheckboxIndependant checked={service_expense_entry} onChange={(e)=>handleChange(e,service_expense_entry,service_expense_entry_set)} name="service_expense_entry" />}
            label="Service Expense Entry" 
          />

<FormControlLabel
            control={<CheckboxIndependant checked={service_record} onChange={(e)=>handleChange(e,service_record,service_record_set)} name="service_record" />}
            label="Service Record" 
          />

<FormControlLabel
            control={<CheckboxIndependant checked={service_expense_record} onChange={(e)=>handleChange(e,service_expense_record,service_expense_record_set)} name="service_expense_record" />}
            label="Service Expense Record" 
          />

<FormControlLabel
            control={<CheckboxIndependant checked={service_voucher} onChange={(e)=>handleChange(e,service_voucher,service_voucher_set)} name="service_voucher" />}
            label="Service Voucher" 
          />

<FormControlLabel
            control={<CheckboxIndependant checked={service_expense_voucher} onChange={(e)=>handleChange(e,service_expense_voucher,service_expense_voucher_set)} name="service_expense_voucher" />}
            label="Service Expense Voucher" 
          />



         </div>
        </FormGroup>
        <FormHelperText>Service Module End</FormHelperText>
      </FormControl>
     
             </Grid>




           


             <Grid item xs={12} sm={3} >
             <FormControl component="fieldset" className={classes.formControl}>

<FormControlLabel style={{marginLeft: '-32px'}} 
    control={<Checkbox checked={purchase}  onChange={(e)=>moduleAllCheck(e)} name="purchase"  />}
    label="Purchase"
  />

  
<FormGroup>
<div id="purchase">
  <FormControlLabel
    control={<CheckboxIndependant checked={purchase_entry} onChange={(e)=>handleChange(e,purchase_entry,purchase_entry_set)} name="purchase_entry" />}
    label="Purchase Entry" 
  />

<FormControlLabel
    control={<CheckboxIndependant checked={purchase_record} onChange={(e)=>handleChange(e,purchase_record,purchase_record_set)} name="purchase_record" />}
    label="Purchase Record" 
  />


<FormControlLabel
    control={<CheckboxIndependant checked={purchase_voucher} onChange={(e)=>handleChange(e,purchase_voucher,purchase_voucher_set)} name="purchase_voucher" />}
    label="Purchase Voucher" 
  />

<FormControlLabel
    control={<CheckboxIndependant checked={purchase_return_voucher} onChange={(e)=>handleChange(e,purchase_return_voucher,purchase_return_voucher_set)} name="purchase_return_voucher" />}
    label="Purchase Return Voucher" 
  />
  <FormControlLabel
    control={<CheckboxIndependant checked={purchase_return} onChange={(e)=>handleChange(e,purchase_return,purchase_return_set)} name="purchase_return" />}
    label="Purchase Return Entry" 
  />
  <FormControlLabel
    control={<CheckboxIndependant checked={purchase_return_record} onChange={(e)=>handleChange(e,purchase_return_record,purchase_return_record_set)} name="purchase_return_record" />}
    label="Purchase Return Record" 
  />


 
 </div>
</FormGroup>
<FormHelperText>Purchase Module End</FormHelperText>
</FormControl>
             </Grid>

             <Grid item xs={12} sm={3} >
             <FormControl component="fieldset" className={classes.formControl}>

<FormControlLabel style={{marginLeft: '-32px'}} 
    control={<Checkbox checked={production}  onChange={(e)=>moduleAllCheck(e)} name="production"  />}
    label="Menufacturing"
  />

  
<FormGroup>
<div id="production">
  <FormControlLabel
    control={<CheckboxIndependant checked={production_entry} onChange={(e)=>handleChange(e,production_entry,production_entry_set)} name="production_entry" />}
    label="Manufacturing Journal Entry" 
  />
 




<FormControlLabel
    control={<CheckboxIndependant checked={production_voucher} onChange={(e)=>handleChange(e,production_voucher,production_voucher_set)} name="production_voucher" />}
    label="Manufacturing Voucher" 
  />

 
 
   <FormControlLabel
    control={<CheckboxIndependant checked={production_record} onChange={(e)=>handleChange(e,production_record,production_record_set)} name="production_record" />}
    label="Manufacturing Record" 
  />

 

  
 </div>
</FormGroup>
<FormHelperText>Production Module End</FormHelperText>
</FormControl>
             </Grid>

             <Grid item xs={12} sm={3} >
             <FormControl component="fieldset" className={classes.formControl}>

          <FormControlLabel style={{marginLeft: '-32px'}} 
              control={<Checkbox checked={inventory}  onChange={(e)=>moduleAllCheck(e)} name="inventory"  />}
              label="Inventory"
            />

            
          <FormGroup>
          <div id="inventory">
            <FormControlLabel
              control={<CheckboxIndependant checked={item_stock_report} onChange={(e)=>handleChange(e,item_stock_report,item_stock_report_set)} name="item_stock_report" />}
              label="Item Stock Report" 
            />
        
              <FormControlLabel
              control={<CheckboxIndependant checked={item_ledger} onChange={(e)=>handleChange(e,item_ledger,item_ledger_set)} name="item_ledger" />}
              label="Item Ledger" 
            />

<FormControlLabel
              control={<CheckboxIndependant checked={transfer_entry} onChange={(e)=>handleChange(e,transfer_entry,transfer_entry_set)} name="transfer_entry" />}
              label="Transfer Entry" 
            />
           
            <FormControlLabel
              control={<CheckboxIndependant checked={item_adjustment_entry} onChange={(e)=>handleChange(e,item_adjustment_entry,item_adjustment_entry_set)} name="item_adjustment_entry" />}
              label="Item Adjustment Entry" 
            />

<FormControlLabel
              control={<CheckboxIndependant checked={transfer_record} onChange={(e)=>handleChange(e,transfer_record,transfer_record_set)} name="transfer_record" />}
              label="Transfer Record" 
            />
            <FormControlLabel
              control={<CheckboxIndependant checked={transfer_pending_record} onChange={(e)=>handleChange(e,transfer_pending_record,transfer_pending_record_set)} name="transfer_pending_record" />}
              label="Transfer pending Record" 
            />

            <FormControlLabel
              control={<CheckboxIndependant checked={transfer_receive_record} onChange={(e)=>handleChange(e,transfer_receive_record,transfer_receive_record_set)} name="transfer_receive_record" />}
              label="transfer receive record" 
            />

<FormControlLabel
              control={<CheckboxIndependant checked={adjustment_record} onChange={(e)=>handleChange(e,adjustment_record,adjustment_record_set)} name="adjustment_record" />}
              label="adjustment  record" 
            />


          
           
          </div>
          </FormGroup>
          <FormHelperText>Inventory Module End</FormHelperText>
          </FormControl>
             </Grid>



             
             <Grid item xs={12} sm={3} >
             <FormControl component="fieldset" className={classes.formControl}>

          <FormControlLabel style={{marginLeft: '-32px'}} 
              control={<Checkbox checked={accounts}  onChange={(e)=>moduleAllCheck(e)} name="accounts"  />}
              label="Financial Accounting"
            />

            
          <FormGroup>
          <div id="accounts">
          <FormControlLabel
              control={<CheckboxIndependant checked={creditor_payment} onChange={(e)=>handleChange(e,creditor_payment,creditor_payment_set)} name="creditor_payment" />}
              label="creditor/ supplier payment entry" 
            />



<FormControlLabel
              control={<CheckboxIndependant checked={debtor_receipt} onChange={(e)=>handleChange(e,debtor_receipt,debtor_receipt_set)} name="debtor_receipt" />}
              label="Debtor receipt entry" 
            />
<FormControlLabel
              control={<CheckboxIndependant checked={expense_entry} onChange={(e)=>handleChange(e,expense_entry,expense_entry_set)} name="expense_entry" />}
              label="Expense entry" 
            />
            <FormControlLabel
              control={<CheckboxIndependant checked={income_entry} onChange={(e)=>handleChange(e,income_entry,income_entry_set)} name="income_entry" />}
              label="Income entry" 
            />

<FormControlLabel
              control={<CheckboxIndependant checked={advance_transaction_entry} onChange={(e)=>handleChange(e,advance_transaction_entry,advance_transaction_entry_set)} name="advance_transaction_entry" />}
              label="advance transaction entry" 
            />

<FormControlLabel
              control={<CheckboxIndependant checked={branch_tran} onChange={(e)=>handleChange(e,branch_tran,branch_tran_set)} name="branch_tran" />}
              label="Branch Transaction entry" 
            />

<FormControlLabel
              control={<CheckboxIndependant checked={contra_entry} onChange={(e)=>handleChange(e,contra_entry,contra_entry_set)} name="contra_entry" />}
              label="Contra entry" 
            />
            <FormControlLabel
              control={<CheckboxIndependant checked={journal_entry} onChange={(e)=>handleChange(e,journal_entry,journal_entry_set)} name="journal_entry" />}
              label="Journal entry" 
            />
            <FormControlLabel
              control={<CheckboxIndependant checked={account_entry} onChange={(e)=>handleChange(e,account_entry,account_entry_set)} name="account_entry" />}
              label="Account entry" 
            />
             <FormControlLabel
              control={<CheckboxIndependant checked={location_entry} onChange={(e)=>handleChange(e,location_entry,location_entry_set)} name="location_entry" />}
              label="Location entry" 
            />
          </div>
          </FormGroup>
          <FormHelperText>Financial Accounting Module End</FormHelperText>
          </FormControl>
             </Grid>


               
             <Grid item xs={12} sm={3} >
             <FormControl component="fieldset" className={classes.formControl}>

          <FormControlLabel style={{marginLeft: '-32px'}} 
              control={<Checkbox checked={hrpayroll}  onChange={(e)=>moduleAllCheck(e)} name="hrpayroll"  />}
              label="HR Payroll"
            />

            
          <FormGroup>
          <div id="hrpayroll">
            <FormControlLabel
              control={<CheckboxIndependant checked={salary_payment} onChange={(e)=>handleChange(e,salary_payment,salary_payment_set)} name="salary_payment" />}
              label="Salary Payment Entry" 
            />
            
            <FormControlLabel
            control={<CheckboxIndependant checked={employee_entry} onChange={(e)=>handleChange(e,employee_entry,employee_entry_set)} name="employee_entry" />}
            label="Employee Entry" 
          />
          <FormControlLabel
              control={<CheckboxIndependant checked={department_entry} onChange={(e)=>handleChange(e,department_entry,department_entry_set)} name="department_entry" />}
              label="Department Entry" 
            />


<FormControlLabel
              control={<CheckboxIndependant checked={designation_entry} onChange={(e)=>handleChange(e,designation_entry,designation_entry_set)} name="designation_entry" />}
              label="Designation Entry" 
            />



<FormControlLabel
              control={<CheckboxIndependant checked={attendance_entry} onChange={(e)=>handleChange(e,attendance_entry,attendance_entry_set)} name="attendance_entry" />}
              label="Attendance Entry" 
            />
<FormControlLabel
              control={<CheckboxIndependant checked={monthly_salary_report} onChange={(e)=>handleChange(e,monthly_salary_report,monthly_salary_report_set)} name="monthly_salary_report" />}
              label="Monthly salary report" 
            />


          
         
          </div>
          </FormGroup>
          <FormHelperText>HRPayroll Module End</FormHelperText>
          </FormControl>
             </Grid>
          
          

              
             <Grid item xs={12} sm={3} >
             <FormControl component="fieldset" className={classes.formControl}>

          <FormControlLabel style={{marginLeft: '-32px'}} 
              control={<Checkbox checked={administration}  onChange={(e)=>moduleAllCheck(e)} name="administration"  />}
              label="settings"
            />

            
          <FormGroup>
          <div id="administration">
            <FormControlLabel
              control={<CheckboxIndependant checked={item_entry} onChange={(e)=>handleChange(e,item_entry,item_entry_set)} name="item_entry" />}
              label="Item Entry" 
            />
            
          
            
          
            
            <FormControlLabel
              control={<CheckboxIndependant checked={item_category} onChange={(e)=>handleChange(e,item_category,item_category_set)} name="item_category" />}
              label="Item  Category Entry" 
            />

<FormControlLabel
              control={<CheckboxIndependant checked={group_entry} onChange={(e)=>handleChange(e,group_entry,group_entry_set)} name="group_entry" />}
              label="Group Entry" 
            />
            
            <FormControlLabel
              control={<CheckboxIndependant checked={item_unit} onChange={(e)=>handleChange(e,item_unit,item_unit_set)} name="item_unit" />}
              label="Item Unit Entry" 
            />
            
            <FormControlLabel
              control={<CheckboxIndependant checked={user_entry} onChange={(e)=>handleChange(e,user_entry,user_entry_set)} name="user_entry" />}
              label="User Entry" 
            />

<FormControlLabel
              control={<CheckboxIndependant checked={warehouse_entry} onChange={(e)=>handleChange(e,warehouse_entry,warehouse_entry_set)} name="warehouse_entry" />}
              label="Warehouse Entry" 
            />

            
            <FormControlLabel
              control={<CheckboxIndependant checked={branch_entry} onChange={(e)=>handleChange(e,branch_entry,branch_entry_set)} name="branch_entry" />}
              label="Branch Entry" 
            />

            
            <FormControlLabel
              control={<CheckboxIndependant checked={company_profile} onChange={(e)=>handleChange(e,company_profile,company_profile_set)} name="company_profile" />}
              label="Company Profile Change" 
            />

<FormControlLabel
              control={<CheckboxIndependant checked={sms_sender} onChange={(e)=>handleChange(e,sms_sender,sms_sender_set)} name="sms_sender" />}
              label="Sms Sender" 
            />
            



          
         
          </div>
          </FormGroup>
          <FormHelperText>settings Module End</FormHelperText>
          </FormControl>
             </Grid>


                
             <Grid item xs={12} sm={3} >
             <FormControl component="fieldset" className={classes.formControl}>

          <FormControlLabel style={{marginLeft: '-32px'}} 
              control={<Checkbox checked={reports}  onChange={(e)=>moduleAllCheck(e)} name="reports"  />}
              label="Reports"
            />

            
          <FormGroup>
          <div id="reports">

          <FormControlLabel
              control={<CheckboxIndependant checked={balance_sheet} onChange={(e)=>handleChange(e,balance_sheet,balance_sheet_set)} name="balance_sheet" />}
              label="balance sheet" 
            />

<FormControlLabel
              control={<CheckboxIndependant checked={trial_balance} onChange={(e)=>handleChange(e,trial_balance,trial_balance_set)} name="trial_balance" />}
              label="trial balance" 
            />


<FormControlLabel
              control={<CheckboxIndependant checked={profit_loss} onChange={(e)=>handleChange(e,profit_loss,profit_loss_set)} name="profit_loss" />}
              label="profit loss" 
            />

<FormControlLabel
              control={<CheckboxIndependant checked={item_wise_profit_loss} onChange={(e)=>handleChange(e,item_wise_profit_loss,item_wise_profit_loss_set)} name="item_wise_profit_loss" />}
              label="item wise profit/loss" 
            />




<FormControlLabel
              control={<CheckboxIndependant checked={cash_bank_balance} onChange={(e)=>handleChange(e,cash_bank_balance,cash_bank_balance_set)} name="cash_bank_balance" />}
              label="cash & bank balance" 
            />

<FormControlLabel
              control={<CheckboxIndependant checked={loan_balance} onChange={(e)=>handleChange(e,loan_balance,loan_balance_set)} name="loan_balance" />}
              label="loan balance" 
            />

<FormControlLabel
              control={<CheckboxIndependant checked={indirect_expense_balance} onChange={(e)=>handleChange(e,indirect_expense_balance,indirect_expense_balance_set)} name="indirect_expense_balance" />}
              label="indirect expense balance" 
            />

<FormControlLabel
              control={<CheckboxIndependant checked={indirect_income_balance} onChange={(e)=>handleChange(e,indirect_income_balance,indirect_income_balance_set)} name="indirect_income_balance" />}
              label="indirect income balance" 
            />
            <FormControlLabel
              control={<CheckboxIndependant checked={fixed_asset_balance} onChange={(e)=>handleChange(e,fixed_asset_balance,fixed_asset_balance_set)} name="fixed_asset_balance" />}
              label="fixed asset balance" 
            />

<FormControlLabel
              control={<CheckboxIndependant checked={capital_balance} onChange={(e)=>handleChange(e,capital_balance,capital_balance_set)} name="capital_balance" />}
              label="capital balance" 
            />

<FormControlLabel
              control={<CheckboxIndependant checked={debtor_balance} onChange={(e)=>handleChange(e,debtor_balance,debtor_balance_set)} name="debtor_balance" />}
              label="debtor balance" 
            />


<FormControlLabel
              control={<CheckboxIndependant checked={branch_balance} onChange={(e)=>handleChange(e,branch_balance,branch_balance_set)} name="branch_balance" />}
              label="branch balance" 
            />

<FormControlLabel
              control={<CheckboxIndependant checked={creditor_balance} onChange={(e)=>handleChange(e,creditor_balance,creditor_balance_set)} name="creditor_balance" />}
              label="creditor balance" 
            />

<FormControlLabel
              control={<CheckboxIndependant checked={fixed_asset_ledger} onChange={(e)=>handleChange(e,fixed_asset_ledger,fixed_asset_ledger_set)} name="fixed_asset_ledger" />}
              label="fixed asset ledger" 
            />

<FormControlLabel
              control={<CheckboxIndependant checked={loan_ledger} onChange={(e)=>handleChange(e,loan_ledger,loan_ledger_set)} name="loan_ledger" />}
              label="loan ledger" 
            />

<FormControlLabel
              control={<CheckboxIndependant checked={indirect_expense_ledger} onChange={(e)=>handleChange(e,indirect_expense_ledger,indirect_expense_ledger_set)} name="indirect_expense_ledger" />}
              label="indirect expense ledger" 
            />

            
<FormControlLabel
              control={<CheckboxIndependant checked={indirect_income_ledger} onChange={(e)=>handleChange(e,indirect_income_ledger,indirect_income_ledger_set)} name="indirect_income_ledger" />}
              label="indirect income ledger" 
            />

<FormControlLabel
              control={<CheckboxIndependant checked={cash_bank_ledger} onChange={(e)=>handleChange(e,cash_bank_ledger,cash_bank_ledger_set)} name="cash_bank_ledger" />}
              label="cash bank ledger" 
            />

<FormControlLabel
              control={<CheckboxIndependant checked={capital_ledger} onChange={(e)=>handleChange(e,capital_ledger,capital_ledger_set)} name="capital_ledger" />}
              label="capital ledger" 
            />

            
<FormControlLabel
              control={<CheckboxIndependant checked={debtor_ledger} onChange={(e)=>handleChange(e,debtor_ledger,debtor_ledger_set)} name="debtor_ledger" />}
              label="debtor ledger" 
            />

<FormControlLabel
              control={<CheckboxIndependant checked={creditor_ledger} onChange={(e)=>handleChange(e,creditor_ledger,creditor_ledger_set)} name="creditor_ledger" />}
              label="creditor ledger" 
            />

<FormControlLabel
              control={<CheckboxIndependant checked={sales_ledger} onChange={(e)=>handleChange(e,sales_ledger,sales_ledger_set)} name="sales_ledger" />}
              label="sales ledger" 
            />

<FormControlLabel
              control={<CheckboxIndependant checked={purchase_ledger} onChange={(e)=>handleChange(e,purchase_ledger,purchase_ledger_set)} name="purchase_ledger" />}
              label="purchase ledger" 
            />

<FormControlLabel
              control={<CheckboxIndependant checked={service_expense_ledger} onChange={(e)=>handleChange(e,service_expense_ledger,service_expense_ledger_set)} name="service_expense_ledger" />}
              label="service expense ledger" 
            />

<FormControlLabel
              control={<CheckboxIndependant checked={service_ledger} onChange={(e)=>handleChange(e,service_ledger,service_ledger_set)} name="service_ledger" />}
              label="service ledger" 
            />

<FormControlLabel
              control={<CheckboxIndependant checked={sales_return_ledger} onChange={(e)=>handleChange(e,sales_return_ledger,sales_return_ledger_set)} name="sales_return_ledger" />}
              label="sales return ledger" 
            />

<FormControlLabel
              control={<CheckboxIndependant checked={purchase_return_ledger} onChange={(e)=>handleChange(e,purchase_return_ledger,purchase_return_ledger_set)} name="purchase_return_ledger" />}
              label="purchase return ledger" 
            />
<FormControlLabel
              control={<CheckboxIndependant checked={tax_ledger} onChange={(e)=>handleChange(e,tax_ledger,tax_ledger_set)} name="tax_ledger" />}
              label="tax ledger" 
            />

            
<FormControlLabel
              control={<CheckboxIndependant checked={direct_expense_ledger} onChange={(e)=>handleChange(e,direct_expense_ledger,direct_expense_ledger_set)} name="direct_expense_ledger" />}
              label="direct expense ledger" 
            />

<FormControlLabel
              control={<CheckboxIndependant checked={debtor_receipt_record} onChange={(e)=>handleChange(e,debtor_receipt_record,debtor_receipt_record_set)} name="debtor_receipt_record" />}
              label="debtor receipt record" 
            />

<FormControlLabel
              control={<CheckboxIndependant checked={creditor_payment_record} onChange={(e)=>handleChange(e,creditor_payment_record,creditor_payment_record_set)} name="creditor_payment_record" />}
              label="creditor payment record" 
            />

<FormControlLabel
              control={<CheckboxIndependant checked={expense_record} onChange={(e)=>handleChange(e,expense_record,expense_record_set)} name="expense_record" />}
              label="expense record" 
            />

<FormControlLabel
              control={<CheckboxIndependant checked={income_record} onChange={(e)=>handleChange(e,income_record,income_record_set)} name="income_record" />}
              label="income record" 
            />

<FormControlLabel
              control={<CheckboxIndependant checked={journal_record} onChange={(e)=>handleChange(e,journal_record,journal_record_set)} name="journal_record" />}
              label="journal record" 
            />
<FormControlLabel
              control={<CheckboxIndependant checked={contra_record} onChange={(e)=>handleChange(e,contra_record,contra_record_set)} name="contra_record" />}
              label="contra record" 
            />

<FormControlLabel
              control={<CheckboxIndependant checked={salary_report} onChange={(e)=>handleChange(e,salary_report,salary_report_set)} name="salary_report" />}
              label="salary Payment report" 
            />
<FormControlLabel
              control={<CheckboxIndependant checked={direct_expense_balance} onChange={(e)=>handleChange(e,direct_expense_balance,direct_expense_balance_set)} name="direct_expense_balance" />}
              label="direct expense balance" 
            />

<FormControlLabel
              control={<CheckboxIndependant checked={direct_income_balance} onChange={(e)=>handleChange(e,direct_income_balance,direct_income_balance_set)} name="direct_income_balance" />}
              label="direct income balance" 
            />

            
<FormControlLabel
              control={<CheckboxIndependant checked={direct_income_ledger} onChange={(e)=>handleChange(e,direct_income_ledger,direct_income_ledger_set)} name="direct_income_ledger" />}
              label="direct income ledger" 
            />



<FormControlLabel
              control={<CheckboxIndependant checked={branch_tran_pen_list} onChange={(e)=>handleChange(e,branch_tran_pen_list,branch_tran_pen_list_set)} name="branch_tran_pen_list" />}
              label="branch tran pending list" 
            />


<FormControlLabel
              control={<CheckboxIndependant checked={branch_tran_rcv_list} onChange={(e)=>handleChange(e,branch_tran_rcv_list,branch_tran_rcv_list_set)} name="branch_tran_rcv_list" />}
              label="branch tran receive list" 
            />

<FormControlLabel
              control={<CheckboxIndependant checked={branch_tran_transfer_list} onChange={(e)=>handleChange(e,branch_tran_transfer_list,branch_tran_transfer_list_set)} name="branch_tran_transfer_list" />}
              label="branch tran transfer list" 
            />


<FormControlLabel
              control={<CheckboxIndependant checked={branch_ledger} onChange={(e)=>handleChange(e,branch_ledger,branch_ledger_set)} name="branch_ledger" />}
              label="branch ledger" 
            />


<FormControlLabel
              control={<CheckboxIndependant checked={daily_ledger} onChange={(e)=>handleChange(e,daily_ledger,daily_ledger_set)} name="daily_ledger" />}
              label="Daily ledger" 
            />


<FormControlLabel
              control={<CheckboxIndependant checked={advance_tran_record} onChange={(e)=>handleChange(e,advance_tran_record,advance_tran_record_set)} name="advance_tran_record" />}
              label="advance tran record" 
            />

<FormControlLabel
              control={<CheckboxIndependant checked={advance_debtor_balance} onChange={(e)=>handleChange(e,advance_debtor_balance,advance_debtor_balance_set)} name="advance_debtor_balance" />}
              label="advance debtor balance" 
            />

<FormControlLabel
              control={<CheckboxIndependant checked={advance_creditor_balance} onChange={(e)=>handleChange(e,advance_creditor_balance,advance_creditor_balance_set)} name="advance_creditor_balance" />}
              label="advance creditor balance" 
            />


            


          </div>
          </FormGroup>
          <FormHelperText>Reports Module End</FormHelperText>
          </FormControl>
             </Grid>



             <Grid item xs={12} sm={3} >
        
        <FormControl component="fieldset" className={classes.formControl}>

        <FormControlLabel style={{marginLeft: '-32px'}} 
            control={<Checkbox checked={quotation}  onChange={(e)=>moduleAllCheck(e)} name="quotation"  />}
            label="Quotation"
          />

          
        <FormGroup>
        <div id="quotation">
          <FormControlLabel
            control={<CheckboxIndependant checked={quotation_entry} onChange={(e)=>handleChange(e,quotation_entry,quotation_entry_set)} name="quotation_entry" />}
            label="Quotation Entry" 
          />

<FormControlLabel
            control={<CheckboxIndependant checked={quotation_record} onChange={(e)=>handleChange(e,quotation_record,quotation_record_set)} name="quotation_record" />}
            label="Quotation Record" 
          />


         
         
         </div>
        </FormGroup>
        <FormHelperText>Quotation Module End</FormHelperText>
      </FormControl>
     
             </Grid>


            <Grid item xs={12} sm={12} >


                    <Button style={{fontSize:'12px'}} 
                            onClick={()=>userAccessSave()}
                            variant="contained"
                            color="primary"
                            size="small"
                            className={classes.button}
                            startIcon={<SaveIcon/>}
                        >
                       Save Access
                      </Button>


                      <Button style={{fontSize:'12px',marginLeft: '20px'}} 
                            
                            variant="contained"
                            color="primary"
                            size="small"
                            className={classes.button}
                            onClick={()=>userAccessBack()}
                            startIcon={<ArrowBackIcon/>}
                        >
                       Back
                      </Button>

                      
             </Grid>
            </Grid>
        </Paper>
   
          </div>
      )
}


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
      },
      formControl: {
        margin: theme.spacing(3),
      },
    root: {
      flexGrow: 1,
    },
    inputField:{
      width:'100%',
      marginTop:'5px'
    },
    plusLinkDiv:{
      position:'relative'  
    },
    plusLink:{
      margin: 0,
      padding: 0,
      marginTop: '-21px',
      fontSize: '29px',
      height: '21px',
      textAlign: 'right',
      position: 'absolute',
      right: 0,
      color: '#3e8d54'
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));


const mapStateToPops = (state)=>{
      return {
        currentRoute:state.currentRouteReducer,
        authInfo:state.authInfoReducer
      }
}
export default connect(mapStateToPops,{currentRouteSet})(UserAccess);