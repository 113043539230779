import React, { useState,useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import '../../commons/voucher.css'
import commaNumber from 'comma-number';
let format = commaNumber.bindWith(',', '.')
const VoucherBody = ({purchaseOrderData,authInfo})=>{
    const classes = useStyles();
  

         let [purchaseOrder,purchaseOrderSet] = useState([])
          let [purchaseOrderDetails,purchaseOrderDetailsSet] = useState([])

          let [is_serial,is_serial_set] = useState('')
          let [is_cal_type,is_cal_type_set] = useState('')

        useEffect(()=>{
            purchaseOrderSet(purchaseOrderData)
            
            is_serial_set(purchaseOrderData.is_serial)
            is_cal_type_set(purchaseOrderData.is_cal_type)

            if(purchaseOrderData.details!=undefined){
                purchaseOrderDetailsSet(purchaseOrderData.details)

            }
            },[purchaseOrderData])

         


      return(
          <>
        <table className={'invoice-table'}> 
          <thead>
              <tr>
                <td  align="left"  width="1%">SL</td>
                <td align="left" width="65%">Particulars</td>

              <td align="right"  width="30%">Amount</td>
          
      

              </tr>
                
             
          </thead>
          <tbody>
        
                    {
                        purchaseOrderDetails.map((item,ind)=>(<>
                               <tr>
                                 <td  style={{textAlign:'left'}}>{parseFloat(1)+ind}</td>
                                 <td align="left">{item.into_acc_name} {authInfo.is_voucher_receipt == 'yes' && item.voucher_no != '' ? ` , Voucher No :  ${item.voucher_no} `: ''} </td>
                                  <td align="right">{format(parseFloat(item.rcv_total).toFixed(2))}</td>
                                </tr>
                        </>))
                    }
          
                
           

               
              

              
          </tbody>
        </table>
          </>
      )
}


const useStyles = makeStyles((theme) => ({
    
    
  }));

export default VoucherBody;
